.alpha-table.users {
    overflow-y: auto;
    height: calc(100% - 58px);
}

.alpha-table.users {
    td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;