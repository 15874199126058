@import './variables.less';

// Override default telerik styles
.k-editor {
    button {
      height: 38px !important;
    }
    .k-editor-content {
      font-size: 14px;
    }
    &.comment-input-empty {
      .k-toolbar {
        display: none;
      }
    }
  }
  
  .k-editor-toolbar .k-button.k-selected {
    background-color: @main-blue;
  }

  .k-animation-container-shown {
    z-index: 10000 !important;
  }

  .k-animation-container {
    .k-selected {
        background-color: @main-blue !important;
      }
  }

  .k-toolbar .k-dropdownlist {
    font-size: 14px;
  }
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;