@import './variables.less';

.report-viewer-container {
    .trv-nav.k-widget {
        border: none;
    }
    .k-icon, .k-link {
        color: @main-blue;
    }
    .k-menu:not(.k-context-menu)>.k-item{
        color: @main-blue;
    }
    .\!k-bg-primary {
        background-color: @main-blue !important;
    }
    .k-menu:not(.k-context-menu)>.k-item.k-hover, .k-menu:not(.k-context-menu)>.k-item:hover {
        color: @main-blue
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;