
@import './variables.less';

.side-menu-container {
    .profile-icon-container {
        cursor: pointer;
        position: absolute;
        bottom: 12px;
        left: 12px;
  
        .ant-avatar {
          height: 36px;
          line-height: 36px;
          width: 36px;
        }
  
        .avatar-icon {
          display: flex;
          flex-flow: row;
          height: 50px;
          align-items: center;
        }
    }
    .sections-container {
      overflow: auto; 
      height: calc(100% - 50px);
      scrollbar-color: @main-blue @main-blue;
      &:hover {
        scrollbar-color:  #c2c2c2 @main-blue;
      }
      &.collapsed {
        height: calc(100% - 100px);
      }
    }
    z-index: 1000;
    padding-top: 27px;
    background-color: #273C75;
    .side-menu {
      height: calc(100vh - 90px);      
      .header-container {
        align-items: center;
        padding-left: 19px;
        margin-bottom: 20px;
        color: #fff;
        .collapse-menu {
          margin-left: auto;
        }
        .controls-container {
            justify-content: flex-end;
            display: flex;
            width: 42%;
        }
      }
      ul.ant-menu {
        background-color: #273C75;
        color: #fff;
      }
      .ant-menu-title-content {
        > div {
          display: flex;
          align-items: center;
        }
        .expand-icon-container{
          margin-left: auto;
        }
      }
      .ant-menu-item-selected, .ant-menu-item-active {
        background-color: #455A91;
        color: #fff;
      }
      .ant-menu-item.selected {
        background: #12224B;
      }
  
      .opened-tabs {
        color: #fff;
        overflow: auto;
        &.hidden {
          .frame {
            .titles-frame {
              display: none;
            }
            .icon-wrapper {
              display: none !important;
            }
          }
        }
        .frame {
          align-items: center;
          display: flex;
          gap: 10px;
          padding: 10px 15px;
          cursor: pointer;
          &.selected {
            background: #12224B;
          }
          .status-wrapper {
            align-items: center;
            display: inline-flex;
            gap: 10px;
            padding: 5px;
            .status {
              border: 1px solid #bcb4b4;
              border-radius: 2px;
              width: 12px;
              height: 12px;
            }
            i {
              margin-right: 0;
            }
          }
          &:hover {
            background-color: #ffffff1a;
            .icon-wrapper {
              display: flex;
            }
          }
          .icon-wrapper {
            align-items: center;
            display: none;
            justify-content: space-around;
            padding: 2px;
            position: relative;
          }
        }
      }
  
      .ant-menu-title-content {
        color: #fff;
      }
      i.nav-profile {
        height: 18px;
        width: 18px;
      }
      .divider {
        background: #fff;
        opacity: 10%;
        margin-left: 18px;
        margin-right: 18px;
        > div {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .pinned-tasks-title {
        color: #fff;
        padding-left: 18px;
        font-size: 12px;
      }
      .hint {
        color: @alpha-background-gray-dark;
        width: 125px;
        margin: auto;
        margin-top: 25px;
        text-align: center;
        font-size: 12px;
        i {
          vertical-align: middle;
        }
        &.hidden {
        display: none;
        }
      }
    }
    &.collapsed {
      .side-menu {
        .alpha-icon {
          margin-right: 0;
          &.admin-icon {
            width: 20px;
          }
        }
        li.ant-menu-item{
          margin-left: 4px;
          padding-left: 14px !important; // TO override antd inline styles
        }
        margin-left: 0px;
        .collapse-menu {
          rotate: 180deg;  
          margin-right: -10px;
        }
        .ant-menu-submenu {
          .ant-menu-title-content {
            overflow: unset;
          }
          .analysed-doc-menu-container {
            width: 20px;
          }
        }
        .pinned-tasks-title,.hint {
          display: none;
        }
      }
      .ant-layout-sider-zero-width-trigger {
        transition: 0.2s;
      }
      .ant-menu {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    
        .collapsed-divider {
          display: block;
        }
      }
      .header-container {
        .alpha-icon.logo {
          margin-left: -4px;
        }
        span {
          display: none;
        }
      }
      .ant-menu-title-content .title{
        display: none;
      }
      .controls-container {
        text-align: center;
        position: relative;
        .user-control {
          margin-bottom: 20px;
        }
      }
    }
  }

.sider-collapser {
    position: absolute;
    right: -58px;
    cursor: pointer;
    top: 27px;
}

i.pin-white-icon {
  background-image: url('../modules/common/resources/icons/pin-white.svg');
}

i.admin-icon {
  background-image: url('../modules/common/resources/icons/admin.svg');
}

i.star-icon {
  background-image: url('../modules/common/resources/icons/star.svg');
}

i.play-icon {
  background-image: url('../modules/common/resources/icons/play.svg');
}

i.assignments-icon {
  background-image: url('../modules/common/resources/icons/assignments.svg');
}

i.dashboard-icon {
  background-image: url('../modules/common/resources/icons/dashboard.svg');
}

i.reports-icon {
  background-image: url('../modules/common/resources/icons/reports.svg');
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;