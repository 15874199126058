/* @import '~antd/dist/antd.less';*/
@import '~react-minimap/dist/react-minimap.css';
@import './variables.less';

.no-anims {
  * {
    animation-duration: 0s !important;
    transition: 0s !important;
  }
}

body {
  min-width: 100%;
  overflow: hidden;
}

// Preload icons to prevent blinking on first hover
body:after {
  visibility: hidden;
  position: absolute;
  top: -30px;
  left: -30px;
  content: url('../modules/common/resources/icons/form-anchor-active.svg');
}

input[type=number]:not(:hover) {
  -moz-appearance:textfield;
}

#root > #offline-overlay > .offline-msg-container {
  display: inline-block;
  height: auto;
}

.offline-msg-container {
  display: inline-block;
  height: auto;
}

#offline-overlay {
  align-items: center;
  background: white;
  color: @alpha-font-color-black;
  display: flex;
  font-size: 32px;
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 10000;

  .offline-msg-header {
    font-size: 50px;
    margin-bottom: 20px;
    text-align: left;
  }
}

 /* GLOBAL STYLES */
.ant-legacy-form-item-control.has-error {
  .ant-select {
    .ant-select-selector {
      border-color: red;
    }
  }
}

.cognitive-search-thumbnail {
  display: inline-block;
  height: 45px;  
  margin: 3px 10px 3px 3px;
  width: 45px;
}

.cognitive-search-thumbnail.person-thumbnail {
  background: lightgray;
  border-radius: 50%;
}

.search-result-url {
  color: @link-color;
  cursor: pointer;
  display: inline-block;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-layout.main-layout, .ant-layout-footer.alpha-layout-footer {
  background: @alpha-background-gray;
}

.alpha-pagination-container {
  .alpha-pagination {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    color: white;
    font-size: 12px;
    height: 44px;
    line-height: 6px;
    margin: 0 auto;
    padding: 15px;
    min-width: 196px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .page-info {
      display: inline-block;
      height: 16px;
      line-height: 16px;
    }

    button.ant-btn {
      margin-top: -2px;
      padding: 0;

      i.alpha-icon {
        margin-right: 0;
      }
    }

    i.alpha-icon.sm {
      height: 15px;
      width: 15px;
    }

    i.alpha-icon.sm:not(.reset-scale) {
      height: 13px;
      width: 13px;
    }

    button.ant-btn:not(:first-child) {
      margin-left: 23px;
    }
  }
}

.ant-layout.main-layout {
  min-height: 100%;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}

div.header-container {
  display: flex;
  height: 26px;
  i.alpha-icon.logo.lg {
    height: 26px;
    margin: 0;
    width: 26px;
  }
}
.header-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  padding-left: 39px;
  padding-right: 0px;
  padding-top: 14px;
  position: sticky;
  top: 0px;
  z-index: 1;
  .title-wrapper {
    flex: 1 1;
    .header-title {
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
    }
  }
}

.ant-tag.alpha-tag {
  border-radius: 12px;
  font-size: 11px;
  height: 19px;
  line-height: 11px;
  padding: 3px 7px;
}

i.alpha-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;  
  margin-right: 7px;
}

i.alpha-icon.no-margin {
  margin-right: 0;
}

i.alpha-icon.success {
  color: @alpha-color-success;
}

i.alpha-icon.danger {
  color: @alpha-color-danger;
}

i.alpha-icon.primary {
  color: @primary-color;
}
i.alpha-icon.open-session {
  width: 100%;
  height: 100%;
}

i.alpha-icon.xxxs {
  font-size: 10px;
  height: 10px;
  width: 10px;
}


i.alpha-icon.xxs {
  font-size: 13px;
  height: 13px;
  width: 13px;
}

i.alpha-icon.xs {
  font-size: 15px;
  height: 15px;
  width: 15px;
}

i.alpha-icon.sm {
  font-size: 20px;
  height: 20px;
  width: 20px;
}

i.alpha-icon.md {
  font-size: 24px;
  height: 24px;
  width: 24px;
}

i.alpha-icon.lg {
  font-size: 36px;
  height: 36px;
  width: 36px;
}

i.alpha-icon.sm.extra-height {
  height: 23px;
}

i.alpha-icon.middle {
  vertical-align: middle;
}

i.alpha-icon.logo {
  background-image: url('../modules/common/resources/icons/alfa_fin.svg');
  border-radius: 4px;
  background-size: 26px 26px;
}

i.alpha-icon.circle-right {
  background-image: url('../modules/common/resources/icons/home-open-sessions.svg');
}

i.alpha-icon.clock {
  background-image: url('../modules/common/resources/icons/home-log.svg');
}

i.alpha-icon.archive {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/home-archive.svg');
}

i.alpha-icon.doc-word {
  background-image: url('../modules/common/resources/icons/doc-word.svg');
}

i.alpha-icon.doc-xml {
  background-image: url('../modules/common/resources/icons/doc-xml.svg');
}

i.alpha-icon.doc-xls {
  background-image: url('../modules/common/resources/icons/doc-xls.svg');
}

i.alpha-icon.doc-pdf {
  background-image: url('../modules/common/resources/icons/doc-pdf.svg');
}

i.alpha-icon.multiple-doc-pdf {
  background-image: url('../modules/common/resources/icons/multiple-pdf.svg');
}

i.alpha-icon.doc-placeholder {
  background-image: url('../modules/common/resources/icons/doc-placeholder.svg');
}

i.alpha-icon.nav-home {
  background-image: url('../modules/common/resources/icons/nav-home.svg');
}

i.alpha-icon.nav-archive {
  background-image: url('../modules/common/resources/icons/nav-archive.svg');
}

i.alpha-icon.nav-profile {
  background-image: url('../modules/common/resources/icons/nav-profile.svg');
}

i.alpha-icon.nav-close-tab {
  background-image: url('../modules/common/resources/icons/nav-close-tab.svg');
}

i.alpha-icon.package-icon {
  background-image: url('../modules/common/resources/icons/package-icon.svg');
}

i.alpha-icon.bell-icon {
  background-image: url('../modules/common/resources/icons/bell-icon.svg');
}

i.alpha-icon.gear {
  background-image: url('../modules/common/resources/icons/icon-gear.svg');
}

i.alpha-icon.eye-visible {
  background-image: url('../modules/common/resources/icons/eye-visible.svg');
}

i.alpha-icon.eye-invisible {
  background-image: url('../modules/common/resources/icons/eye-invisible.svg');
}

i.alpha-icon.reset-scale {
  background-image: url('../modules/common/resources/icons/reset-scale.svg');
}

i.alpha-icon.calendar-icon {
  background-image: url('../modules/common/resources/icons/calendar.svg');
}

i.alpha-icon.arrow-down-icon {
  background-image: url('../modules/common/resources/icons/arrow-down.svg');
}


i.alpha-icon.package-set {
  background-image: url('../modules/common/resources/icons/package-set-icon.svg');
}

i.alpha-icon.package-xls-icon {
  background-image: url('../modules/common/resources/icons/package-xls-icon.svg');
}

i.alpha-icon.nav-analysis {
  background-image: url('../modules/common/resources/icons/nav-analysis.svg');
}

i.alpha-icon.overflow-indicator {
  background-image: url('../modules/common/resources/icons/overflow-indicator.svg');
}

i.alpha-icon.arrow-right-circle {
  background-image: url('../modules/common/resources/icons/session-complete.svg');
}

i.alpha-icon.success {
  background-image: url('../modules/common/resources/icons/archive-session-ok.svg');
}

i.alpha-icon.error {
  background-image: url('../modules/common/resources/icons/archive-session-cancelled.svg');
}

i.alpha-icon.new-session {
  background-image: url('../modules/common/resources/icons/new-session.svg');
}

i.alpha-icon.plus {
  background-image: url('../modules/common/resources/icons/plus.svg');
}

i.alpha-icon.plus-circle {
  background-image: url('../modules/common/resources/icons/modal-header-icon.svg');
}

i.alpha-icon.mail {
  background-image: url('../modules/common/resources/icons/action-share.svg');
}

i.alpha-icon.download {
  background-image: url('../modules/common/resources/icons/download.svg');
}

i.alpha-icon.form-anchor {
  background-image: url('../modules/common/resources/icons/form-anchor.svg');
}

i.alpha-icon.form-anchor.active, i.alpha-icon.form-anchor:hover {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/form-anchor-active.svg');
}

i.alpha-icon.zoom-in {
  background-image: url('../modules/common/resources/icons/preview-zoom-in.svg');
}

i.alpha-icon.zoom-in.inactive {
  background-image: url('../modules/common/resources/icons/preview-zoom-in-inactive.svg');
}

i.alpha-icon.zoom-out {
  background-image: url('../modules/common/resources/icons/preview-zoom-out-active.svg');
}

i.alpha-icon.zoom-out.inactive {
  background-image: url('../modules/common/resources/icons/preview-zoom-out.svg');
}

i.alpha-icon.zoom {
  background-image: url('../modules/common/resources/icons/preview-zoom.svg');
}

i.alpha-icon.archive {
  background-image: url('../modules/common/resources/icons/archive.svg');
}

i.alpha-icon.home-upload-file {
  background-image: url('../modules/common/resources/icons/home-upload-file.svg');
}

i.alpha-icon.reload {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/session-reload.svg');
}

i.alpha-icon.session-switch {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/session-switch.svg');
}

i.alpha-icon.search {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/search.svg');
}

i.alpha-icon.table-arror-right {
  background-image: url('../modules/common/resources/icons/table-arrow-right.svg');
}

i.alpha-icon.circle-cross {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/circle-cross.svg');
}

i.alpha-icon.cross {
  cursor: pointer;
  background-image: url('../modules/common/resources/icons/cross.svg');
}

i.modal-close {
  background-image: url('../modules/common/resources/icons/modal-close.svg');
}

i.alpha-icon.edit-pen{
  background-image: url('../modules/common/resources/icons/edit-pen.svg');
}

i.alpha-icon.show-in-doc{
  background-image: url('../modules/common/resources/icons/show-in-doc.svg');
}

i.alpha-icon.sort-arrow-up {
  background-image: url('../modules/common/resources/icons/sort-arrow-up.svg');
}

i.alpha-icon.sort-arrow-down {
  background-image: url('../modules/common/resources/icons/sort-arrow-down.svg');
}

i.alpha-icon.dragger {
  background-image: url('../modules/common/resources/icons/dragger.svg');
}

i.alpha-icon.columns {
  background-image: url('../modules/common/resources/icons/columns.svg');
}

i.alpha-icon.lock {
  background-image: url('../modules/common/resources/icons/lock.svg');
}

i.alpha-icon.user {
  background-image: url('../modules/common/resources/icons/user.svg');
}

i.alpha-icon.shrink {
  background-image: url('../modules/common/resources/icons/shrink.svg');
}

i.alpha-icon.arrow-right {
  background-image: url('../modules/common/resources/icons/arrow-right.svg');
}

i.alpha-icon.checked-bordered {
  background-image: url('../modules/common/resources/icons/checked-bordered.svg');
}

i.alpha-icon.attachment {
  background-image: url('../modules/common/resources/icons/attachment-icon.svg');
}

i.alpha-icon.table-action-delete {
  background-image: url('../modules/common/resources/icons/table-action-delete.svg');
}

i.alpha-icon.table-action-edit {
  background-image: url('../modules/common/resources/icons/table-action-edit.svg');
}

i.alpha-icon.expand {
  background-image: url('../modules/common/resources/icons/expand.svg');
}

i.alpha-icon.projects-white {
  background-image: url('../modules/common/resources/icons/projects-white.svg');
}

i.alpha-icon.members-white {
  background-image: url('../modules/common/resources/icons/members-white.svg');
}

i.alpha-icon.task-types {
  background-image: url('../modules/common/resources/icons/task-types.svg');
}

i.alpha-icon.flag {
  background-image: url('../modules/common/resources/icons/flag.svg');
}

i.alpha-icon.meta {
  background-image: url('../modules/common/resources/icons/meta.svg');
}

i.alpha-icon.action {
  background-image: url('../modules/common/resources/icons/action.svg');
  background-size: 0.9em;
}

i.close-panel-icon {
  background-image: url('../modules/common/resources/icons/close-panel.svg');
}

i.subtask-icon {
  background-image: url('../modules/common/resources/icons/subtask-indicator.svg');
}

i.pin-icon {
  background-image: url('../modules/common/resources/icons/pin.svg');
}

i.unpin-icon {
  background-image: url('../modules/common/resources/icons/unpin.svg');
}

i.danger-delete-icon {
  background-image: url('../modules/common/resources/icons/danger-delete.svg');
}

.alpha-antd-icon {
  font-size: 20px;
  line-height: 20px;
  margin-right: 8px;
  &.no-margin {
    margin-right: 0px;
  }
}

.dropdown-btn {
  i.alpha-icon {
    vertical-align: text-top;
  }
}

.alpha-dropdown-overlay {
  .ant-dropdown-menu-item:hover {
    background-color: @alpha-background-gray;
  }
}

.dropdown-btn.ant-dropdown-open {
  color: @primary-color;
}

.alpha-modal-upload-container {
    .ant-upload.ant-upload-drag {
      background: white;
      border-color: white;
    
      .label-with-icon {
        color: @primary-color;
      }
    }

    .ant-upload.ant-upload-drag.ant-upload-drag-hover {
      border-color: @primary-color;
    }
}

.header-content {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 50px;
  line-height: 50px;
  overflow: hidden;

  .main-menu-container {
    margin-left: 30px;

    .ant-menu {
      .ant-menu-item {
        margin: -1px 0 0;
        padding: 0 20px;
      }

      .ant-menu-item:after {
        display: none;
      }
    }

    i.alpha-icon.xs {
      height: 24px;
      width: 24px;
      top: 13px;
      left: 15px;
      position: static;
      left: 13.16%;
      right: 65.79%;
      top: 26%;
      bottom: 26%;
    }
  }
}

.ant-layout-header.app-page-header {
  background: @primary-color;
  font-size: 15px;
  height: 50px;
  z-index: 1000;

  .ant-menu-submenu {
    border: none;
    height: 50px;

    .ant-menu-submenu-title {
      color: white;
    }
  }

  .ant-menu-submenu:hover, .ant-menu-submenu.ant-menu-submenu-active, .ant-menu-submenu.ant-menu-submenu-open {
    border: none;
    color: white;
  }

  .ant-menu-horizontal {
    border: none;
  }

  .document-tabs-container {
    color: white;
    height: 50px;

    .document-tabs-menu {
      cursor: pointer;
      padding: 0 16px;      
    }

    .document-tabs-menu:hover {
      background: @primary-color-hover;
    }
    .tab-title {
      display: inline-block;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      margin: 10px 0;
      padding: 0 18px 0 15px;
      white-space: nowrap;
      vertical-align: top;
      width: 250px;
    }
  }

  .ant-menu {
    background: @primary-color;
    color: #fff;
    flex-grow: 1;
    flex-shrink: 1;

    .ant-menu-item {
      border: none;
      font-size: 14px;
      height: 50px;
      padding: 0 15px;
      top: 0;

      .alpha-icon.xs {
        font-size: 16px;
        height: 16px;
        margin-right: 12px;
        vertical-align: middle;
        width: 16px;
      }
    }

    .ant-menu-item.ant-menu-item-active {
      color: #fff;
    }
  
    .ant-menu-item.ant-menu-item-selected {
      color: #fff;
      background: @primary-color-hover;
    }

    .ant-menu-item:hover {
      color: #fff;
      background: @tab-background-color-hover;

      .tab-title, .btn-container {
        border-color: @tab-background-color-hover;
      }
    }
  }

  .controls-container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-end;

    .user-control {
      cursor: pointer;
      padding: 0 12px;

      i.alpha-icon {
        margin: 0;
      }
    }

    .user-control.has-notifications {
      padding-right: 20px;
    }

    .user-control:hover {
      background-color: @primary-color-hover;
    }
  }
}

.ant-popover.notifications-container { 
  position: fixed;
  transform: translateX(8px);
}

.notifications-container.full-screen {
  .ant-list.notifications-list {
    max-height: calc(100vh - 140px);
    overflow: hidden auto;
  }
}

.notifications-container {
  min-width: 400px;

  .notifications-container-header {
    .notifications-header-title {
      color: @alpha-font-color-black;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .notifications-container-footer {
    border-top: 1px solid @alpha-border-color;
    color: @primary-color;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    padding: 20px 20px 10px;
    text-align: center;

    i.alpha-icon {
      margin-left: 5px;
    }
  }

  .notifications-container-footer:hover {
    text-decoration: underline;
  }
  
  .ant-popover-inner-content {
    padding: 20px 20px 0;
  }

  .notifications-list {
    cursor: default;
    text-align: right;
    
    .ant-list-item {
      padding: 12px;
      text-align: left;

      .ant-list-item-action-split {
        display: none;
      }

      .ant-list-item-meta {
        align-items: center;
      }

      .ant-list-item-meta-title {
        color: @alpha-font-color-gray;
        cursor: pointer;
        font-weight: 300;
        margin-bottom: 0;
        word-break: break-all;
      }

      .ant-list-item-meta-title:hover {
        text-decoration: underline;
      }

      .ant-list-item-meta-description {
        font-size: 11px;
      }

      .notification-mark-as-read {
        background-color: @primary-color;
        border-radius: 9px;
        display: inline-block;
        height: 9px;
        width: 9px;
      }
      
      .ant-list-item-action {
        li {
          padding: 0;
          margin-right: -16px;
          vertical-align: bottom;
        }
      }
    }

    .ant-list-item.unread {
      .ant-list-item-meta-title {
        color: @alpha-font-color-black;
        font-weight: 500;
      }
    }

    .notification-action-stub {
      width: 48px;
    }
  }
}

.page-title {
  color: #000;
  font-size: 26px;
  font-weight: 700;
}

.ant-card.content-card.table-content {
  .card-header {
    padding: @alpha-card-padding;
  }

  .ant-card-body {
    padding: 0;
  }
}

.ant-card.content-card.table-content.group:not(:last-of-type) {
  margin-bottom: 20px;
}

.ant-card.content-card.table-content.group {
  .group-header {
    font-weight: bold;
    i.alpha-icon {
      vertical-align: middle;
    }
  }
}

.ant-card.content-card {
  border: 1px solid @alpha-border-color;
  color: #000;
  margin-top: 20px;
  min-height: 200px;

  .card-header {
    .content-card-title {
      font-size: 16px;   
      font-weight: 500;
    }

    .alpha-icon, .content-card-title {
      vertical-align: middle;
    }
  }

  .ant-card-body {    
    padding: 20px;
  }
}

.ant-card.content-card.shadow {
  box-shadow: 0px 0px 5px 0px rgba(146, 146, 146, 0.27);
}

.ant-card.content-card.borderless {
  border: none;
}

.ant-card.content-card.danger {
  border: 1px solid @alpha-border-color-danger;
}

.alpha-label, .label-with-icon {
  i, span {
    vertical-align: middle;
  }
  
}

.alpha-label.success {
  color: @alpha-color-success;
}

.alpha-label.danger {
  color: @alpha-color-danger;
}

.alpha-label.faded {
  color: @alpha-font-color-gray;
}

.no-padding-collapse {
  .ant-collapse-content { 
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.alpha-table.ant-table-wrapper {
  .ant-table-tbody > tr > td {    
    background: transparent;
    padding: 11px @alpha-card-padding;   
  }

  .ant-table-thead > tr > th {    
    border-bottom: 1px solid @alpha-border-color;
    border-top: 1px solid @alpha-border-color;
    color: @alpha-font-color-gray;
    font-size: 14px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid @alpha-border-color;
    font-size: 14px;

    * {
      font-size: 14px;
    }

    .ant-tag.alpha-info-tag {
      font-size: 11px;
      vertical-align: middle;
    }

    .ant-select.priority-select {
      .ant-select-selector {
        padding-left: 0px;
      }
    }
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #F5F6F8;
    .closed-session-operation{
      visibility: visible;
    }
  }

  .ant-table-tbody > tr.closed > td {
    color: @alpha-font-color-gray;

    .priority-select, .ant-btn {
      color: @alpha-font-color-gray;
    }

    .priority-select {
      .alpha-icon.priority-medium, .alpha-icon.priority-high {
        background-image: url('../modules/tasks/resources/icons/priority-low.svg');
      }
      .ant-select-selector-selected-value {
        text-decoration: line-through;
      }
    }

    .ant-btn > span {
      text-decoration: line-through;
    }
  }

  .ant-table-tbody > tr.selected > td {
    background-color: @alpha-background-gray-dark;
  }

  .ant-table-tbody {
    color: @alpha-font-color-black;

    .ant-table-selection-column {
      overflow: hidden;
      text-overflow: clip;
    }
  }

  .ant-table-footer {
    background: transparent;
    text-align: center;
  }

  .table-footer-link {
    color: @primary-color;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
  }

  .table-footer-link:hover {
    color: @primary-color-hover;
  }

  .ant-pagination.ant-table-pagination {
    padding-right: 16px;
  }
}

.alpha-table.ant-table-wrapper.no-border-top {
  .ant-table-thead > tr > th {
    border-top: none;
  }
}


.alpha-table.ant-table-wrapper.fixed {
  table {
    table-layout: fixed !important;

    .ant-table-tbody > tr > td {
      overflow: hidden;
      text-overflow: ellipsis;
      
      .ant-btn, span, div {
        max-width: 100%;        
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
      }

      .ant-btn > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }
    }

    .ant-table-tbody > tr > td.ant-table-selection-column {
      overflow: hidden;
      text-overflow: clip;
    }
  }
}

.alpha-table.alpha-table.ant-table-wrapper.sm-rows {
  .ant-table-tbody > tr > td {
    padding: 6px 16px;
  }
}

.alpha-table.alpha-table.ant-table-wrapper.lg-title {
  .ant-table-thead > tr > th {
    padding: 6px 16px;
    white-space: nowrap;
    overflow: hidden;
    
    .ant-table-column-sorters {
      padding: 8px 0;
    }
  }
  .ant-table-tbody > tr > td {
    * {      
      font-size: 14px;
    }

    .ant-tag.alpha-info-tag {
      font-size: 11px;
    }

    .entry-title, .entry-title * {
      font-size: 14px;
    }
  }
}

.ant-alert.ant-alert-error.ant-alert-closable.push-service-status-bar ~ main.ant-layout-content {
  .task-properties-modal.drawer:not(.full-screen) {
    height: calc(100vh - 190px);
    top: 190px;
  }
}


.dialog-header {
  margin-bottom: 20px;

  .title {
    color: @alpha-font-color-black;
    font-size: 26px;
    font-weight: 700;
    margin-right: 8px;
    vertical-align: middle;
    word-break: break-all;
  }

  .title.closed {
    color: @alpha-font-color-gray;
    text-decoration: line-through;
  }
}

::-webkit-input-placeholder { /* Chrome */
  color: red;
}

.tasks-content-header, .session-archive-header{
  display: flex;
  flex-flow: row nowrap;
  padding-top: 14px;

  .alpha-search-input.ant-input-search {
    width: auto;
    transition: .3s;

    .ant-input-affix-wrapper {
      width: auto;

      .ant-input {
        width: 55px;
      }

      .ant-input:focus {
        width: 250px;
      }
    }

    input.ant-input {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

.label {
  color: @alpha-font-color-black;
  margin-right: 4px;
}

.label.faded {
  color: @alpha-font-color-gray;
}

.label.link {
  color: @primary-color;
  cursor: pointer;
}

.label.link:hover {
  color: @primary-color-hover;
}

.label.small {
  font-size: 11px;
}

.label.bold {
  font-weight: 700;
}

.label.separate-right {
  margin-right: 15px;
}

.subtitle {
  color: @alpha-font-color-black;
  font-size: 15px;
}

.modal-header-container {
  margin: 20px 0 15px;
  padding: 0 15px;
  .analysis-sort {
    width: 40px;
    display: inline-block;
    text-align: left;
    margin: 0;
  }
}

.modal-controls-container {
  margin: 20px 0 0;
  padding: 0 15px;

  .ant-input-search {
    .ant-btn {
      background-color: white;
      border-left: 1px solid transparent;
      color: @alpha-font-color-gray;
      padding: 1px 15px;
      width: 48px;
    }

    .ant-btn:hover, .ant-btn:focus {
      border-left: 1px solid @primary-color-hover;
      color: @alpha-font-color-gray-dark;
    }

    input {
      border-right: 1px solid;
    }

    input:not(:hover):not(:focus) {
      border-right-color: transparent;
    }
  }
}

.modal-header {
  justify-content: space-between;
  align-items: center;
  .icons-container {
    align-items: center;
    display: flex;
    i:not(:last-of-type) {
      margin-right: 15px;
      cursor: pointer;
    }
  }
}

.project-list-item.ant-list-item {
  color: @alpha-font-color-black;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: left;
  padding-left: 15px;

  > div:first-child {
    flex: 0 0 25px;
  }

  .info-label {
    color: @alpha-font-color-gray;
    font-size: 11px;
    margin-right: 7px;
  }

  .additional-info {
    max-width: 600px;
  }
}
.ant-list-split .project-list-item.ant-list-item  {
  border-bottom: unset;
}

.search-blocks-preview {
  color: @alpha-font-color-gray-dark;
  margin-top: 8px;
  max-height: 65px;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  // To show vertical overflow on webkit browsers
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  b {
    color: green;
  }
}

.search-blocks-preview.large {
  display: block;
  margin-left: 40px;
  margin-top: 20px;
  max-height: calc(100vh - 260px);
  max-width: unset;
  overflow: auto;
  padding: 20px 20px 20px 40px;
  white-space: pre;
}

.project-list-item.ant-list-item.selected {
  background-color: @alpha-background-gray;
}

.project-list-item.ant-list-item.processing, .project-list-item.ant-list-item.disabled {
  color: @alpha-font-color-gray;

  i.alpha-icon {
    filter: grayscale(1) contrast(0.1);
  }
}

.project-tag {
  background:  @alpha-font-color-gray;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 3px;
  margin-right: 7px;
  padding: 2px 7px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.project-tag.selectable {
  cursor: pointer;
  transition: .3s;
}

.project-tag.selectable:hover {
  background-color: @primary-color-hover-light;
  transition: .3s;
}


.project-tag.selectable.selected {
  background-color: @primary-color;
  color: white;
  transition: .3s;
}

.alpha-search-input {
  .ant-input {
    border-right: 1px solid transparent;
  }

  .ant-input:hover, .ant-input:focus {
    border-right: 1px solid @primary-color-hover;
  }

  .ant-btn.ant-input-search-button {
    color: @alpha-font-color-gray;
    background-color: white;    
    border-color: #d9d9d9;
    border-left-color: transparent;
    box-shadow: none;    
  }
  .ant-btn.ant-input-search-button:hover, .ant-btn.ant-input-search-button:focus {
    border-color: @primary-color-hover;
    color: @primary-color-hover;
  }
}

.alpha-search-input.ghost {
  .ant-input-prefix {
    margin-top: -2px;
    margin-right: -4px;
  }

  .ant-input-group .ant-input-affix-wrapper {
    border-radius: 4px;
  }

  .ant-input-affix-wrapper {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;

    input {
      background-color: transparent;
    }
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    background-color: white;
    border: 1px solid @alpha-border-color;
  }

  .ant-input-affix-wrapper:hover {
    background-color: white;
    border: 1px solid @primary-color-hover;
  }
}

.alpha-search-input.no-search-btn {
  .ant-input-group {
    .ant-input-affix-wrapper {
      .ant-input {
        border-radius: 4px;
      }
    }
  }

  .ant-input-group-addon {
    display: none;
  }
}

.alpha-dropdown-popup > div {
  overflow: visible !important;

  .ant-select-dropdown-menu {
    overflow: visible;
  }
}

.visual-table-filters {
  margin-bottom: 20px;

  .ant-radio-button-wrapper {
    font-size: 14px;
    height: 26px;
    line-height: 16px;
    padding: 5px 14px;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background-color: transparent;
    color: @alpha-secondary-font-color
  }
}

.alpha-info-tag.ant-tag {
  border-radius: 10px;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px;
  line-height: 11px;
}

.ant-table {
  th.ant-table-cell::before {
    display: none;
  }
  // override antd header column dividers and sorter styles
  th.ant-table-column-has-sorters div.ant-table-column-sorters {
    display: inline-flex;
    .ant-table-column-sorter {
      margin-left: 8px;
    }
  }
}

.preview-doc-wrapper {
  .k-state-disabled {
    opacity: 1;
  }
}

.content-card .alpha-list.ant-list .alpha-list-item {
  margin-bottom: 0;
}

.ant-list.alpha-list.justify-space-between {
  .ant-row {
    justify-content: space-between;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

html, body, #root, #root>div,  #root>div>div {
  height: 100% 
}

.cardContent {
  text-align: center;
  color: white
}

.container {
  position: relative;
  flex-grow: 1
}

.pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  margin-top: 2px;
}

.pagination li {
  display: inline-block;
  cursor: pointer;
}

.pagination > li > a {
  position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #3f51b5;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.pagination >.active> a{
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.pagination > li > a:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
  position: absolute;
}
.homePageCard {
    width: 370px;
    display: inline-block;
    margin-right: 30px;

}

.homePageCard:hover {
  background-color: lightgray;
}

.cardContainer > div {
  background-color: #3f51b5;
}

.ant-alert.ant-alert-error.ant-alert-closable.push-service-status-bar {
  height: 50px;
  background-color: #f8e174;
  line-height: 2.3;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.ant-alert.ant-alert-error.ant-alert-closable.push-service-status-bar + header {
  top: 50px;
}

.ant-alert.ant-alert-error.ant-alert-closable.push-service-status-bar ~ main.ant-layout-content {
  margin-top: 50px;
}

.push-service-status-bar > .anticon {
  line-height: 2;
}

.push-service-status-bar > .ant-alert-close-icon {
  line-height: 30px;
}

.app-version-label {
    border-top: 1px solid @alpha-secondary-border-color;
    color: @alpha-font-color-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    position: static;
    background-color: #F5F6F8;
    z-index: 100;
}

.ant-select.ant-select-single.colored-select {
  border-radius: 4px;

  .ant-select-selector {
    background-color: inherit;
    border-color: inherit;
    color: inherit;
  }
}

.icon-uploader img {
  max-height: 86px;
  max-width: 86px;
}

div > .ant-message {
  z-index: 10001;
}

.home-buttons-container {
  text-align: center;
}

.welcome-text {
  color: @alpha-font-color-black;
  font-size: 34px;
  line-height: 39.84px;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
}

.home-navigation-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 1060px;
  margin: 0px auto;
  text-align: center;

  > .ant-card {
    border: none;
    border-radius: 10px;
    background-color: white;
    cursor: default;
    height: 373px;
    margin: 0 30px 30px 0;
    width: 500px;

    .ant-card-body {
      padding: 30px 20px;
    }

    .home-card-content {
      text-align: left;

      .card-content-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 20px;
      }

      .card-content-body {
        color: @alpha-secondary-font-color;
        font-size: 16px;
        min-height: 57px;
        line-height: 19px;
        margin-bottom: 20px;
      }

      button.ant-btn {
        height: 40px;
      }
    }

    .ant-card-cover {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 150px;
      overflow: hidden;
      width: 500px;

      img {
        transform: translateY(-50px);
      }
    }
  }
}

.no-access-to-portal {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

.alpha-application-form-buttons button {
  z-index: 200;

  .alpha-icon.xs {
    height: 16px;
    vertical-align: middle;
    width: 16px;
  }
}

.freeze-spinner > span {
  display: none
}

.ant-upload.ant-upload-drag p.ant-upload-text.home-upload-text {
  color:  @alpha-font-color-gray;
  margin-bottom: 0;
  font-size: 14px;
  i {
    position: absolute;
    margin-left: -21px;
  }
}

i.search:hover{
  background-image: url('../modules/common/resources/icons/search-hover.svg');
}

i.reload:hover{
  background-image: url('../modules/common/resources/icons/session-reload-hover.svg');
}

.modal-controls-container {
  [data-id="new-contract-search-input"], button{
    height: 42px;
  }
  margin-bottom: 10px;
}

// Red border for text areas with validation errors
textarea[aria-required="true"][aria-invalid="true"] {
  border-color: #ff4d4f;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #c2c2c2;
}

*{
  scrollbar-width: thin;
  scrollbar-color: #fff #fff;
}
*:hover{
  scrollbar-color:  #c2c2c2 #fff;
 }

button.load-more-button {
  margin: auto;
  display: block;
  margin-top: 12px;
}

.alpha-document-container .bottom-container-content.ant-tabs-nav:before  {
  z-index: -100;
}

.ant-pagination {
  li:not(.ant-pagination-item-active) a {
    color: @alpha-font-color-gray;
  }
}

.ant-pagination .anticon-right, .ant-pagination .anticon-left {
  width: 16px;
  height: 13px;
}

.ant-pagination  {
  .ant-pagination-options div {
    color:  @alpha-font-color-gray;
  }
}


.ant-tooltip.alpha-input-tooltip {
  max-width: 700px;

  .ant-tooltip-inner {
    white-space: pre-wrap;
  }
}


.ant-picker-suffix {
  .alpha-icon.xs.calendar-icon {
    width: 14px;
  }
}

.ant-modal-footer button + button {
  margin-left: 8px;
}

.multi-document-loading {
  align-items: center;
  display: flex;
  height: calc(100vh - 160px);
  justify-content: center;
}


.k-window.k-display-inline-flex {
  .k-window-titlebar {
    padding: 0 16px;
  }

  div.k-popup-edit-form {
    padding: 16px;

    .k-action-buttons {
      padding-bottom: 20px;
      padding-left: 16px;
    }
  }
}

.ant-tabs.full-width-tabs {
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        flex: 1 1;
        justify-content: center;
      }
    }
  }
}

.alpha-form.ant-form {
  .ant-select {
    width: 100%;
  }
}

.ant-form {
  label {
    color: @alpha-font-color-gray;
    font-size: 11px;
  }
}

.alpha-modal {
  button {
    height: 40px;
  }
}

//overriding antd required fields style
.ant-form-item-required {
  &::before {
    display: none !important;
  }
  .dialog-field-label::after, .alpha-doc-control-name::after {
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    content: '*';
    line-height: 1;
    vertical-align: sub;
  }
}
.dialog-field-label.required::after {
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  content: '*';
  line-height: 1;
  vertical-align: sub;
}

.nested-table .ant-table-expanded-row {
  .inner-table {
    margin-left: 0px;
  }
}

.ant-table-tbody
  > tr.ant-table-expanded-row
  td
  > .ant-table-wrapper.inner-table {
  .ant-table {
    margin: 0;
    .ant-table-row.ant-table-row-level-0 {
      height: 46px;
    }
  }
}

.alpha-table.nested-table.ant-table-wrapper.sm-rows .ant-table-tbody > tr.ant-table-expanded-row-level-1 > td {
  padding: 0px
}

.alpha-icon.arrow-right.expand-row {
  cursor: pointer;
  vertical-align: middle;
  background-size: unset;
  margin-left: 10px;
  &.hidden {
    visibility: hidden;
  }
}

tr.expanded .alpha-icon.arrow-right,
.alpha-icon.arrow-expand.expanded {
  transform: rotate(90deg);
  transition: transform 0.24s;
  height: 12px;
}


.ant-btn.alpha-dialog-button {
  font-size: 14px;
}

.titles-frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  overflow: hidden;
  p {
    align-self: stretch;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .parent {
    opacity: 0.5;
    font-size: 11px;
    line-height: 13px;
  }
  .title {
    font-size: 13px;
    line-height: 15px;
  }
}

.drop-over-downward td {
  border-bottom: 3px dashed #7ed18dfb !important;
}

.drop-over-upward td {
  border-top: 3px dashed #7ed18dfb;
}

.ant-table-row[draggable=true] {
  cursor: pointer;
  td:first-of-type::before {
    content: url("../modules/common/resources/icons/drag-handle.svg");
    position: absolute;
    left: 10px;
    top: 13px;
  } 
}

i.alpha-icon.collapse-menu {
  background-image: url('../modules/common/resources/icons/collapse-menu.svg');
}

i.alpha-icon.home {
  background-image: url('../modules/common/resources/icons/home.svg');
}

i.alpha-icon.projects {
  background-image: url('../modules/common/resources/icons/projects.svg');
}

i.alpha-icon.analysis {
  background-image: url('../modules/common/resources/icons/analysis.svg');
}

i.alpha-icon.side-menu-archive {
  background-image: url('../modules/common/resources/icons/side-menu-archive.svg');
}

i.alpha-icon.tasks {
  background-image: url('../modules/common/resources/icons/tasks.svg');
}

i.alpha-icon.upload {
  background-image: url('../modules/common/resources/icons/upload.svg');
}

i.alpha-icon.subtask {
  background-image: url('../modules/common/resources/icons/subtask.svg');
}

i.alpha-icon.doc {
  background-image: url('../modules/common/resources/icons/doc-neutral.svg');
}

i.alpha-icon.pie-chart {
  background-image: url('../modules/common/resources/icons/pie-chart.svg');
}

i.alpha-icon.export {
  background-image: url('../modules/common/resources/icons/export.svg');
}

i.alpha-icon.refresh {
  background-image: url('../modules/common/resources/icons/refresh.svg');
}

i.alpha-icon.edit-native-blue {
  background-image: url('../modules/common/resources/icons/edit-pen-native-blue.svg');
}

.upload-dragger {
  .ant-upload.ant-upload-btn {
    height: 100px;
  }
}
button.ant-btn-ghost {
  box-shadow: none;
}

.checkbox-info-tooltip {
  svg {
    margin-left: 10px;
    width: 11px;
    color: @alpha-secondary-font-color;
  }
}


/* 
.combobox {
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    pointer-events: none;
} */
/* .k-textbox-container.k-state-empty>.k-label {
  transform: translate(14px, 20px) scale(1) !important;
  top: 0 !important;
}

.k-textbox-container.k-state-focused>.k-label {
  font-size: 1rem;
  z-index: 10000;
  transform: translate(14px, -6px) scale(0.75) !important;

} 

.k-textbox-container {
  border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.54);
    line-height: 1em;
    transform-origin: top left;
  
} */

.sucess-notification {
  background: linear-gradient(0deg, rgba(82, 196, 26, 0.1), rgba(82, 196, 26, 0.1)), #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  .ant-notification-notice-message {
    margin-bottom: 0 !important;
  }
  .view-task {
    color: #273C75; 
    font-weight: 500; 
    cursor: pointer
  }
}

// TODO: remove this after recharts 3.0 release
// Workaround to remove black outline from recharts pie chart. Will be fixed in recharts 3.0 release
// https://github.com/recharts/recharts/issues/3170
.recharts-pie:focus,
.recharts-pie > g:focus,
.recharts-pie > g > g:focus {
  outline: none !important;
  outline-color: none;
  outline-style: none;
  outline-width: none;
}

// Workaround to remove black border appeared afer browser update in edge.
.ant-collapse-header {
  outline: none;
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;