@import './variables.less';

.table-filter-popup {
    height: 100%;
    padding: 20px;

    .numeric-widget {
      display: flex;
    }
    >div:first-child {
        margin-bottom: 20px;
    }
    .ant-select, .ant-picker, .ant-input-number{
        &.ant-input-number{
            width: 100%;
        }
        width: 240px;
        .ant-select-selection-placeholder {
            text-align: left;
        }
        &.filter-operator{
            width: 60px;
            margin-right: 10px;
            .description {
            display: none;
            }
        }
    }
    .ant-switch {
        margin-top: 20px;
    }
    .controls-container {
        text-align: right;
    }
}
  
.filter-opartor-container {
    width: 200px !important;
    .label {
        padding-right: 15px;
        padding-left: 5px;
    } 
}

i.alpha-icon.filter-reset {
    background-image: url('../modules/common/resources/icons/filter-reset.svg');
}

th {
    .filter-reset {
        display: none;
        vertical-align: bottom;
        margin-left: 7px;
    }

    &.isFiltered {
        .filter-reset:not(.hidden) {
            display: inline-block;
        }
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;