@import './variables.less';

.alpha-modal.widgets-constructor {
    .ant-form-item-label {
        height: 30px;
    }
}

.widgets-constructor-layout {
    .dropdown-btn:not(:hover) {
        color: @alpha-secondary-font-color;
        transition: 0s;
    }
    
    .dropdown-btn {
        box-shadow: none;
        transition: 0s;
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;